<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="column q-col-gutter-sm">
        <q-select
          outlined
          label="Sumber"
          :readonly="idPenerima > 0"
          v-model="selected.bea"
          :options="opt.beasiswa"
        ></q-select>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section class="column q-col-gutter-sm">
        <q-select
          outlined
          label="jenjang"
          :readonly="idPenerima > 0"
          v-model="selected.jenjang"
          :options="$LIST_JENJANG"
          @input="
            selected.siswa = null;
            selected.tingkat = null;
          "
        ></q-select>
        <q-select
          outlined
          label="Tingkat"
          :readonly="idPenerima > 0"
          v-model="selected.tingkat"
          :options="selected.jenjang == 'MA' ? opt.tingkatma : opt.tingkatsmp"
          @input="
            selected.siswa = null;
            getSiswa();
          "
        ></q-select>
        <q-select
          outlined
          label="Siswa"
          :readonly="idPenerima > 0"
          :disable="idPenerima < 0 && selected.tingkat == null"
          use-input
          @filter="filterFn"
          v-model="selected.siswa"
          :options="opt.siswa"
        ></q-select>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section class="column q-col-gutter-sm">
        <q-input
          outlined
          type="number"
          label="Tahun"
          v-model="selected.tahun"
        ></q-input>
        <q-input
          outlined
          type="number"
          label="Nominal"
          v-model="selected.nominal"
        ></q-input>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-actions :align="idPenerima > 0 ? 'between' : 'right'">
        <div v-if="idPenerima > 0">
          <q-btn
            outline
            label="Aktifkan"
            color="positive"
            v-if="selected.is_masih == '0'"
            @click="activate"
          ></q-btn>
          <q-btn
            outline
            label="Non-Aktifkan"
            color="negative"
            v-if="selected.is_masih == '1'"
            @click="activate"
          ></q-btn>
        </div>
        <div>
          <q-btn
            flat
            color="primary"
            label="close"
            @click="onCancelClick"
          ></q-btn>
          <q-btn
            unelevated
            :disable="selected.bea == null || selected.siswa == null"
            color="primary"
            label="SIMPAN"
            @click="addpenerima"
          ></q-btn>
        </div>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import moment from "moment";
export default {
  props: ["idPenerima", "selBea", "listBea"],
  data() {
    return {
      selected: {
        bea: null,
        jenjang: this.$LIST_JENJANG[0],
        tingkat: null,
        siswa: null,
        tahun: moment().format("YYYY"),
        nominal: 0,
      },
      opt: {
        beasiswa: [],
        tingkatsmp: ["7", "8", "9"],
        tingkatma: ["10", "11", "12"],
        siswa: [],
      },
      siswaAll: [],
    };
  },
  async mounted() {
    this.opt.beasiswa = JSON.parse(JSON.stringify(this.listBea));
    this.opt.beasiswa.shift();
    if (this.selBea.value != "semua") {
      this.selected.bea = JSON.parse(JSON.stringify(this.selBea));
    }
    if (this.idPenerima > 0) {
      await this.getPenerimaDetail();
    }
  },
  methods: {
    async activate() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let masih = 1;
      if (this.selected.is_masih == "1") {
        masih = 0;
      }
      await this.$http.put(
        `/siswa/beasiswa/activate/${this.selected.id}/${masih}`
      );
      this.$q.loading.hide();
      this.onOKClick();
    },
    async getPenerimaDetail() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/siswa/beasiswa/getdatapenerima/${this.idPenerima}`
      );
      resp.data.bea = { label: resp.data.nama_bea, value: resp.data.bea };
      resp.data.siswa = { label: resp.data.nama_siswa, value: resp.data.siswa };
      this.selected = resp.data;
      this.$q.loading.hide();
    },
    filterFn(val, update) {
      if (val === "") {
        update(() => {
          this.opt.siswa = this.siswaAll;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.opt.siswa = this.siswaAll.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    async getSiswa() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/siswa/beasiswa/getsiswa/${this.selected.jenjang}/${this.selected.tingkat}`
      );
      this.siswaAll = resp.data;
      this.$q.loading.hide();
    },
    async addpenerima() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let data = JSON.parse(JSON.stringify(this.selected));
      data.bea = data.bea.value;
      data.siswa = data.siswa.value;
      await this.$http.post(`/siswa/beasiswa/addpenerima`, data);
      this.$q.notify({ message: "Siswa Berhasil Disimpan", color: "positive" });
      this.$q.loading.hide();
      this.onOKClick();
    },
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      this.$emit("ok", this.selected);
      this.hide();
    },

    onCancelClick() {
      this.hide();
    },
  },
};
</script>
