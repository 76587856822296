<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="column q-col-gutter-sm">
        <q-input outlined label="Nama" v-model="beasiswa.nama"></q-input>
        <q-select
          outlined
          label="Sumber"
          v-model="beasiswa.sumber"
          :options="opt.sumber"
        ></q-select>
        <q-select
          outlined
          label="Jenis"
          v-model="beasiswa.jenis"
          :options="opt.jenis"
        ></q-select>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-actions align="right">
        <q-btn
          flat
          color="primary"
          label="close"
          @click="onCancelClick"
        ></q-btn>
        <q-btn
          unelevated
          :disable="beasiswa.nama == '' || !beasiswa.sumber || !beasiswa.jenis"
          v-if="idBea < 0"
          color="primary"
          label="SIMPAN"
          @click="simpan"
        ></q-btn>
        <q-btn
          unelevated
          v-else
          :disable="beasiswa.nama == ''"
          color="primary"
          label="EDIT"
          @click="edit"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["idBea"],
  data() {
    return {
      beasiswa: {
        nama: "",
        sumber: null,
        jenis: null,
      },
      opt: {
        jenis: ["PRESTASI", "KURANG MAMPU", "TAHFIDZ", "LAINYA"],
        sumber: [
          "KEMENAG",
          "KEMENDIKBUD",
          "KEMENTRIAN LAIN",
          "PEMERINTAH DAERAH",
          "BUMN",
          "BUMD",
          "INSTANSI SWASTA",
          "YAYASAN",
          "PERORANGAN",
          "GNOTA",
          "SUBSIDI INTERNAL A",
          "SUBSIDI INTERNAL B",
          "SUBSIDI INTERNAL C",
        ],
      },
    };
  },
  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    if (this.idBea > 0) {
      await this.getBeaDetail();
    }
    this.$q.loading.hide();
  },
  methods: {
    async getBeaDetail() {
      let resp = await this.$http.get(
        `/siswa/beasiswa/getdetail/${this.idBea}`
      );
      this.beasiswa = resp.data;
    },
    async simpan() {
      await this.$http.post(`/siswa/beasiswa/addbea`, this.beasiswa);
      this.$q.notify({
        message: "Beasiswa Berhasil Disimpan",
        color: "positive",
      });
      this.onOKClick();
    },
    async edit() {
      await this.$http.put(`/siswa/beasiswa/editbea`, this.beasiswa);
      this.$q.notify({
        message: "Perubahan Beasiswa Berhasil Disimpan",
        color: "positive",
      });
      this.onOKClick();
    },
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      this.$emit("ok", this.beasiswa);
      this.hide();
    },

    onCancelClick() {
      this.hide();
    },
  },
};
</script>
