<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between">
        <div class="row q-gutter-sm">
          <q-select
            outlined
            label="jenjang"
            v-model="jenjang"
            :options="$LIST_JENJANG"
            @input="getPenerima"
            style="width:150px"
          ></q-select>
          <q-select
            outlined
            stack-label
            label="Beasiswa"
            :options="bea"
            v-model="selBea"
            style="width:300px"
            @input="getPenerima"
          >
            <template v-if="selBea" v-slot:append>
              <q-icon
                v-if="selBea.value != 'semua'"
                name="edit"
                @click.stop="showAddBeasiswa(selBea.value)"
                class="cursor-pointer"
              />
            </template>
          </q-select>
          <q-btn
            unelevated
            icon="add"
            color="primary"
            label="Tambah Beasiswa"
            @click="showAddBeasiswa(-1)"
          ></q-btn>
        </div>
        <q-input
          outlined
          label="Cari Siswa/NISN"
          v-model="searchTerm"
          @input="filterPage()"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
      >
        <thead>
          <tr>
            <th>No</th>
            <th>Nama</th>
            <th>Tahun</th>
            <th>Nominal</th>
            <th>Masih</th>
            <th style="width:30px"></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(val, i) in filteredPenerima" :key="i">
            <td class="text-center">
              {{ (pagination.current - 1) * 10 + i + 1 }}
            </td>
            <td>{{ val.nama_siswa }}</td>
            <td class="text-center">{{ val.tahun }}</td>
            <td class="text-center">
              {{
                val.nominal | currency("Rp.", 0, { thousandsSeparator: "." })
              }}
            </td>
            <td class="text-center">
              <q-checkbox
                disable
                true-value="1"
                false-value="0"
                v-model="val.is_masih"
              ></q-checkbox>
            </td>
            <td>
              <q-btn
                flat
                icon="edit"
                color="secondary"
                @click="showAddPenerima(val.id)"
              ></q-btn>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-actions align="between">
        <q-btn
          :disable="isEmptyList"
          outline
          color="primary"
          label="Download"
          @click="download"
        ></q-btn>
        <q-pagination
          v-model="pagination.current"
          :disable="!selBea"
          :max="pagination.max"
          input
          input-class="text-orange-10"
          @input="filterPage"
        />
        <q-btn
          unelevated
          :disable="!selBea"
          color="primary"
          label="Tambah Siswa"
          @click="showAddPenerima(-1)"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import DialogEditBeasiswa from "@/components/DialogEditBeasiswa";
import DialogPenerimaBeasiswa from "@/components/DialogPenerimaBeasiswa";
import XLSX from "xlsx";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
export default {
  data() {
    return {
      searchTerm: "",
      jenjang: this.$LIST_JENJANG[0],

      listPenerima: [],
      filteredPenerima: [],

      selBea: null,
      bea: [],

      pagination: {
        current: 1,
        max: 0,
        limit: 10,
      },
    };
  },
  async mounted() {
    await this.getbealist();
  },
  computed: {
    isEmptyList() {
      return this.listPenerima.length == 0;
    },
  },
  methods: {
    download() {
      let temp = JSON.parse(JSON.stringify(this.listPenerima));

      for (let item of temp) {
        item.id_beasiswa = this.bea.find((x) => {
          return x.value == item.id_beasiswa;
        });
        item.nama_beasiswa = item.id_beasiswa.label;
        delete item.id_beasiswa;
      }

      var ws = XLSX.utils.json_to_sheet(temp);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "PENERIMA BEASISWA");
      XLSX.writeFile(
        wb,
        `PENERIMA BEASISWA ${this.selBea.label} ${this.jenjang}.xlsx`
      );
    },
    filterPage() {
      let temp = JSON.parse(JSON.stringify(this.listPenerima));

      if (this.searchTerm != "") {
        temp = temp.filter((x) => {
          let cNama =
            x.nama_siswa.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !=
            -1;
          let cNISN = val.nisn.indexOf(this.searchTerm) != -1;
          return cNama || cNISN;
        });
      }

      let show = [];
      for (let i = 0; i < 10; i++) {
        if (temp[(this.pagination.current - 1) * 10 + i]) {
          show.push(temp[(this.pagination.current - 1) * 10 + i]);
        }
      }
      this.pagination.max = Math.ceil(temp.length / 10);
      this.filteredPenerima = show;
    },
    async getPenerima() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/siswa/beasiswa/getpenerimalist/${this.jenjang}/${this.selBea.value}`
      );
      this.listPenerima = resp.data;
      this.filterPage();
      this.$q.loading.hide();
    },
    async getbealist() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(`/siswa/beasiswa/getbealist`);
      resp.data.unshift({ label: "SEMUA", value: "semua" });
      this.bea = resp.data;
      this.$q.loading.hide();
    },
    showAddBeasiswa(id) {
      this.$q
        .dialog({
          component: DialogEditBeasiswa,
          parent: this,
          idBea: id,
        })
        .onOk(async (resp) => {
          await this.getbealist();
          this.selBea = this.bea.find((x) => {
            return x.label == resp.nama;
          });
          await this.getPenerima();
        });
    },
    showAddPenerima(id) {
      this.$q
        .dialog({
          component: DialogPenerimaBeasiswa,
          parent: this,
          idPenerima: id,
          selBea: this.selBea,
          listBea: this.bea,
        })
        .onOk(async (resp) => {
          this.selBea = resp.bea;
          this.jenjang = resp.jenjang;
          await this.getPenerima();
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
